<template>
  <section class="mb-3">
    <div class="card">
      <div class="card-content">
        <div class="is-flex is-justify-content-space-between">
          <h2 class="subtitle m-0">Endereço da empresa</h2>
          <a
            @click="edit"
            @mouseenter="() => setIconType('is-info')"
            @mouseleave="() => setIconType('is-default')"
          >
            <b-icon icon="pencil" :type="iconType" />
          </a>
        </div>
        <hr />
        <div class="content">
          <div class="columns">
            <div class="column is-half">
              <b-field label="Rua">
                <span>{{ address.street }}</span>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Número">
                <span>{{ address.residencialNumber }}</span>
              </b-field>
            </div>
            <div class="column">
              <b-field label="CEP">
                <span>{{ getZipCode(address.zipCode) }}</span>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Bairro">
                <span>{{ address.neighborhood }}</span>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Complemento">
                <span>{{
                  address.complement ? address.complement : 'N/A'
                }}</span>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AddressPreview',
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      iconType: 'is-default',
    };
  },
  methods: {
    edit() {
      this.$emit('edit-address-clicked');
    },
    getZipCode(zipCode) {
      const pattern = '#####-###';
      let i = 0;
      return pattern.replace(/#/g, () => zipCode[i++] || '');
    },
    setIconType(iconType) {
      this.iconType = iconType;
    },
  },
};
</script>
