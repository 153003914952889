<template>
  <!-- 00.000.000/0000-00 -->
  <b-field :type="{ 'is-danger': $v.cnpj.$error && !!validationMessage }">
    <template #label>
      CNPJ
      <b-tooltip type="is-dark" label="Digite o CNPJ da sua empresa">
        <b-icon size="is-small" icon="help-circle-outline"></b-icon>
      </b-tooltip>
    </template>
    <b-input
      v-model="$v.cnpj.$model"
      icon="file-document"
      :disabled="disabled"
      v-cleave="getCnpjMask"
      aria-placeholder="Digite o CNPJ"
      placeholder="Digite o CNPJ"
      @input="onCnpjInput"
      @blur="validateCnpj"
    />
    <p v-if="$v.cnpj.$error && !!validationMessage" class="help is-danger">
      {{ validationMessage }}
    </p>
  </b-field>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { cnpjService } from '@/services/validations';

export default {
  name: 'CnpjInput',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cnpj: this.value,
      validationMessage: '',
    };
  },
  validations() {
    if (this.required) {
      return {
        cnpj: {
          required,
          validateCnpj(cnpj) {
            const cnpjTrim = this.trimCnpjMask(cnpj);
            return cnpjService.isValid(cnpjTrim);
          },
        },
      };
    } else {
      return {
        cnpj: {
          validateCnpj(cnpj) {
            const cnpjTrim = this.trimCnpjMask(cnpj);
            return cnpjService.isValid(cnpjTrim);
          },
        },
      };
    }
  },
  computed: {
    getCnpjMask() {
      return {
        blocks: [2, 3, 3, 4, 2],
        delimiterLazyShow: true,
        delimiters: ['.', '.', '/', '-'],
        numericOnly: true,
      };
    },
  },
  methods: {
    onCnpjInput(cnpj) {
      if (this.$v.cnpj.validateCnpj) {
        this.$emit('input', this.trimCnpjMask(cnpj));
      } else {
        this.$emit('input', null);
      }
    },
    trimCnpjMask(cnpj) {
      return cnpj?.replace(/[^0-9]/g, '');
    },
    validateCnpj() {
      if (!this.$v.cnpj.validateCnpj) {
        this.validationMessage = 'CNPJ inválido.';
        return;
      }

      if (!this.$v.cnpj.required) {
        this.validationMessage = 'Preencha o campo CNPJ.';
        return;
      }

      this.validationMessage = '';
    },
  },
};
</script>

<style></style>
