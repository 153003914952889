<template>
  <div>
    <CompanyPreview
      v-if="company"
      :company="company"
      @edit-company-clicked="editCompanyClicked"
    />
    <AddressPreview
      v-if="address"
      :address="address"
      @edit-address-clicked="editAddressClicked"
    />
    <ShareholdersPreview
      v-if="shareholders && shareholders.length"
      :shareholders="shareholders"
      @edit-shareholders-clicked="editShareholdersClicked"
    />
  </div>
</template>

<script>
import { AddressPreview } from '@/components/AddressPreview';
import { CompanyPreview } from '@/components/CompanyPreview';
import { ShareholdersPreview } from '@/components/ShareholdersPreview';

import { customerStorage } from '@/store';

import { shareholdersService } from '@/services/shareholders';

export default {
  name: 'PreviewStep',
  components: {
    AddressPreview,
    CompanyPreview,
    ShareholdersPreview,
  },
  data() {
    return {
      address: null,
      company: null,
      shareholders: [],
    };
  },
  beforeMount() {
    this.initializePreviewStep();
  },
  methods: {
    initializePreviewStep() {
      const customer = customerStorage.getCustomer();

      if (customer) {
        this.company = {
          businessDescription: customer?.businessDescription,
          cnpj: customer?.document,
          financialYearEndDate: '31/12',
          fullName: customer?.name,
          hasCnpj: !!customer?.document,
          id: customer?.id,
          industry: customer?.industryType?.name,
          shareCapital: customer?.shareCapital,
          shareholders: customer?.shareholders,
          website: customer?.websiteUrl,
        };

        this.address = {
          complement: customer?.location?.complement,
          neighborhood: customer?.location?.neighborhood,
          residencialNumber: customer?.location?.residencialNumber,
          street: customer?.location?.street,
          zipCode: customer?.location?.zipcode,
        };

        this.shareholders = shareholdersService.initializeShareholdersValues(
          customer?.shareholders
        );
      }
    },
    editCompanyClicked() {
      this.$emit('edit-company-clicked');
    },
    editAddressClicked() {
      this.$emit('edit-address-clicked');
    },
    editShareholdersClicked() {
      this.$emit('edit-shareholders-clicked');
    },
  },
};
</script>
