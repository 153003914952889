<template>
  <b-select
    :value="regimeOfProperty"
    :disabled="disabled"
    :required="required"
    icon="order-bool-ascending-variant"
    expanded
    placeholder="Selecione o regime matrimonial"
    aria-placeholder="Selecione o regime matrimonial"
    @input="onRegimeOfPropertyInput"
  >
    <option hidden value="">Selecione o regime matrimonial</option>
    <option
      v-for="regimeOfProperty in getRegimesOfProperty"
      :value="regimeOfProperty.value"
      :key="regimeOfProperty.value"
    >
      {{ regimeOfProperty.label }}
    </option>
  </b-select>
</template>

<script>
export default {
  name: 'RegimeOfPropertySelect',
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      regimeOfProperty: this.value,
    };
  },
  watch: {
    required(value) {
      this.required = value;
    },
    value(value) {
      this.regimeOfProperty = value;
    },
  },
  computed: {
    getRegimesOfProperty() {
      return [
        { value: 'COMUNHAO_PARCIAL', label: 'Comunhão parcial de bens' },
        { value: 'COMUNHAO_UNIVERSAL', label: 'Comunhão universal de bens' },
        {
          value: 'PARTICIPACAO_FINAL',
          label: 'Participação final nos aquestos',
        },
        { value: 'SEPARACAO_CONVENCIONAL_BENS', label: 'Separação de bens' },
      ];
    },
  },
  methods: {
    onRegimeOfPropertyInput(regimeOfProperty) {
      this.$emit('input', regimeOfProperty);
    },
  },
};
</script>
