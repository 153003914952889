<template>
  <b-field :type="{ 'is-danger': $v.phone.$error }">
    <template #label>
      Telefone*
      <b-tooltip
        type="is-dark"
        label="Este telefone será usado para contatos via Whatsapp."
        multilined
      >
        <b-icon size="is-small" icon="help-circle-outline"></b-icon>
      </b-tooltip>
    </template>
    <VuePhoneNumberInput
      v-model="$v.phone.$model"
      color="#3f76a6"
      error-color="#f14668"
      valid-color="#dbdbdb"
      :error="$v.phone.$error"
      default-country-code="BR"
      no-example
      :translations="{
        countrySelectorLabel: 'Código do País',
        phoneNumberLabel: 'Informe seu telefone',
      }"
      @update="onUpdatePhone"
    />
    <p v-if="$v.phone.$error" class="help is-danger">
      <span v-if="!$v.phone.required">Preencha este campo.</span>
      <span v-else-if="!$v.phone.minLength">Formato inválido.</span>
    </p>
  </b-field>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: 'PhoneInput',
  props: {
    value: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      phone: this.value,
    };
  },
  validations() {
    if (this.required) {
      return {
        phone: {
          required,
          minLength: minLength(12),
        },
      };
    } else {
      return {
        phone: {
          minLength: minLength(12),
        },
      };
    }
  },
  methods: {
    onUpdatePhone(phoneProps) {
      if (phoneProps?.isValid && phoneProps?.formatNational?.length >= 10) {
        this.$emit('input', phoneProps?.formattedNumber);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>
