<template>
  <b-tag v-if="isSuccess" class="mr-1" type="is-success is-light" rounded
    >✓</b-tag
  >
  <b-tag v-else class="mr-1" type="is-danger is-light" rounded>!</b-tag>
</template>

<script>
export default {
  name: 'StatusTag',
  props: {
    isSuccess: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
