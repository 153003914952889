<template>
  <section>
    <div class="columns">
      <div class="column">
        <CpfInput v-model="cpf" required @input="onDocumentsInput" />
      </div>

      <div class="column is-one-third">
        <b-field label="Estado civil*">
          <MaritalStatusSelect
            v-model="maritalStatus"
            required
            @input="onMaritalStatusInput"
          />
        </b-field>
      </div>

      <div class="column is-one-third">
        <b-field label="Regime matrimonial">
          <RegimeOfPropertySelect
            v-model="regimeOfProperty"
            :required="isMaritalStatusMarried"
            :disabled="!isMaritalStatusMarried"
            @input="onDocumentsInput"
          />
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          label="Cidade de nascimento*"
          :type="{ 'is-danger': $v.placeOfBirth.$error }"
        >
          <b-input
            v-model="$v.placeOfBirth.$model"
            icon="city"
            aria-placeholder="Informe sua cidade de nascimento"
            placeholder="Informe sua cidade de nascimento"
            @input="onDocumentsInput"
          />
          <p v-if="$v.placeOfBirth.$error" class="help is-danger">
            Preencha este campo.
          </p>
        </b-field>
      </div>

      <div class="column">
        <b-field
          label="Nacionalidade*"
          :type="{ 'is-danger': $v.nationality.$error }"
        >
          <b-input
            v-model="$v.nationality.$model"
            icon="earth"
            aria-placeholder="Informe a nacionalidade"
            placeholder="Informe a nacionalidade"
            @input="onDocumentsInput"
          />
          <p v-if="$v.nationality.$error" class="help is-danger">
            Preencha este campo.
          </p>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          label="Comprovante de residência*"
          :type="{ 'is-danger': $v.proofOfResidence.$error }"
        >
          <b-upload
            v-model="$v.proofOfResidence.$model"
            accept=".jpeg,.jpg,.png,.pdf,.doc,.docx,.xls,.xlsx,.tiff,.ppt,.pptx,.bmp,.gif"
            class="file file-label is-primary"
            required
            validationMessage="Por favor, selecione um arquivo válido."
            @input="onDocumentsInput"
          >
            <span class="file-cta">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label">
                {{ proofOfResidence ? '' : 'Selecione um arquivo' }}
              </span>
            </span>
            <span class="file-name" v-if="proofOfResidence">
              {{ proofOfResidence.name }}
            </span>
          </b-upload>
          <p v-if="$v.proofOfResidence.$error" class="help is-danger">
            <span v-if="!$v.proofOfResidence.between">
              Valor máximo permitido 5mb.
            </span>
          </p>
        </b-field>
      </div>

      <div class="column">
        <b-field
          label="Documento com foto*"
          :type="{ 'is-danger': $v.proofOfID.$error }"
        >
          <b-upload
            v-model="$v.proofOfID.$model"
            accept=".jpeg,.jpg,.png,.pdf,.doc,.docx,.xls,.xlsx,.tiff,.ppt,.pptx,.bmp,.gif"
            class="file file-label is-primary"
            required
            validationMessage="Por favor, selecione um arquivo válido."
            @input="onDocumentsInput"
          >
            <span class="file-cta">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label">
                {{ proofOfID ? '' : 'Selecione um arquivo' }}
              </span>
            </span>
            <span class="file-name" v-if="proofOfID">
              {{ proofOfID.name }}
            </span>
          </b-upload>
          <p v-if="$v.proofOfID.$error" class="help is-danger">
            <span v-if="!$v.proofOfID.between">
              Valor máximo permitido 5mb.
            </span>
          </p>
        </b-field>
      </div>
    </div>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import {
  MaritalStatusSelect,
  maritalStatusService,
} from '@/components/MaritalStatusSelect';
import { RegimeOfPropertySelect } from '@/components/RegimeOfPropertySelect';
import { CpfInput } from '@/components/CpfInput';

const FILE_LENGTH_MAX = 5000000; // 5mb

export default {
  name: 'DocumentsForm',
  components: {
    CpfInput,
    MaritalStatusSelect,
    RegimeOfPropertySelect,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      cpf: this.value?.cpf,
      maritalStatus: this.value?.maritalStatus,
      nationality: this.value?.nationality,
      placeOfBirth: this.value?.placeOfBirth,
      proofOfID: this.value?.proofOfID,
      proofOfResidence: this.value?.proofOfResidence,
      regimeOfProperty: this.value?.regimeOfProperty,
    };
  },
  validations() {
    return {
      nationality: {
        required,
      },
      placeOfBirth: {
        required,
      },
      proofOfID: {
        between: function betweenIf() {
          return this.proofOfID ? this.proofOfID?.size < FILE_LENGTH_MAX : true;
        },
      },
      proofOfResidence: {
        between: function betweenIf() {
          return this.proofOfResidence
            ? this.proofOfResidence?.size < FILE_LENGTH_MAX
            : true;
        },
      },
    };
  },
  computed: {
    isMaritalStatusMarried() {
      return maritalStatusService.isMaritalStatusMarried(this.maritalStatus);
    },
  },
  methods: {
    onMaritalStatusInput() {
      if (!this.isMaritalStatusMarried) {
        this.setRegimeOfProperty(null);
      }
      this.onDocumentsInput();
    },
    setRegimeOfProperty(regimeOfProperty) {
      this.regimeOfProperty = regimeOfProperty;
    },
    getDocuments() {
      return {
        cpf: this.cpf,
        maritalStatus: this.maritalStatus,
        nationality: this.nationality,
        placeOfBirth: this.placeOfBirth,
        proofOfID: this.proofOfID,
        proofOfResidence: this.proofOfResidence,
        regimeOfProperty: this.regimeOfProperty,
      };
    },
    onDocumentsInput() {
      this.$emit('input', this.getDocuments());
      this.emitDocumentsReady();
    },
    emitDocumentsReady() {
      const isDocumentsReady = this.isDocumentsReady();
      this.$emit('is-documents-ready', isDocumentsReady);
    },
    isDocumentsReady() {
      const {
        cpf,
        maritalStatus,
        nationality,
        placeOfBirth,
        proofOfID,
        proofOfResidence,
        regimeOfProperty,
      } = this.getDocuments();
      const maritalStatusValid =
        (maritalStatus && !this.isMaritalStatusMarried) ||
        (this.isMaritalStatusMarried && regimeOfProperty);

      return !!(
        cpf &&
        maritalStatusValid &&
        nationality &&
        placeOfBirth &&
        proofOfID &&
        proofOfResidence &&
        this.proofOfID?.size < FILE_LENGTH_MAX &&
        this.proofOfResidence?.size < FILE_LENGTH_MAX
      );
    },
  },
};
</script>
