<template>
  <form>
    <section>
      <div class="columns">
        <div class="column">
          <b-field :type="{ 'is-danger': $v.fullName.$error }">
            <template #label>
              Nome da empresa*
              <b-tooltip type="is-dark" label="Digite o nome da sua empresa">
                <b-icon size="is-small" icon="help-circle-outline"></b-icon>
              </b-tooltip>
            </template>
            <b-input
              v-model="$v.fullName.$model"
              icon="alphabetical"
              aria-placeholder="Digite o nome da empresa"
              placeholder="Digite o nome da empresa"
              @input="onCompanyInput"
            />
            <p v-if="$v.fullName.$error" class="help is-danger">
              Preencha este campo.
            </p>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column is-one-fifth">
          <b-field>
            <template #label>
              Possui CNPJ?*
              <b-tooltip
                type="is-dark"
                label="Selecione se a sua empresa já possui um CNPJ operante no Brasil"
              >
                <b-icon size="is-small" icon="help-circle-outline"></b-icon>
              </b-tooltip>
            </template>
            <b-switch v-model="hasCnpj" @input="onCompanyInput">
              {{ hasCnpj ? 'Sim' : 'Não' }}
            </b-switch>
          </b-field>
        </div>

        <div class="column">
          <!-- 00.000.000/0000-00 -->
          <CnpjInput
            v-model="cnpj"
            :disabled="!hasCnpj"
            @input="onCompanyInput"
          />
        </div>

        <div class="column">
          <b-field :type="{ 'is-danger': $v.shareCapital.$error }">
            <template #label>
              Capital social*
              <b-tooltip
                type="is-dark"
                label="Digite o valor do capital social como está no contrato social da sua empresa no Brasil"
                multilined
              >
                <b-icon size="is-small" icon="help-circle-outline"></b-icon>
              </b-tooltip>
            </template>
            <b-input
              v-model="$v.shareCapital.$model"
              :disabled="!hasCnpj"
              aria-placeholder="Informe o capital social"
              placeholder="Informe o capital social"
              type="number"
              min="0"
              step=".01"
              icon="cash-multiple"
              @input="onCompanyInput"
            />
            <p v-if="$v.shareCapital.$error" class="help is-danger">
              Preencha este campo.
            </p>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-field>
            <template #label>
              Tipo de indústria*
              <b-tooltip
                type="is-dark"
                label="Selecione a opção que mais descreve o seu negócio"
              >
                <b-icon size="is-small" icon="help-circle-outline"></b-icon>
              </b-tooltip>
            </template>
            <IndustrySelect
              v-model="industry"
              required
              @input="onCompanyInput"
            />
          </b-field>
        </div>

        <div class="column">
          <b-field>
            <template #label>
              Site
              <b-tooltip
                type="is-dark"
                label="Se a sua empresa já possuir um site, digite aqui o url do mesmo"
                multilined
              >
                <b-icon size="is-small" icon="help-circle-outline"></b-icon>
              </b-tooltip>
            </template>
            <b-input
              v-model="website"
              icon="web"
              aria-placeholder="Digite o site"
              placeholder="Digite o site"
              @input="onCompanyInput"
            />
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-field :type="{ 'is-danger': $v.businessDescription.$error }">
            <template #label>
              Descrição do negócio*
              <b-tooltip type="is-dark" multilined>
                <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                <template v-slot:content>
                  Descreva em algumas palavras o seu negócio. Por exemplo: qual
                  o setor, o que a empresa faz, quais problemas quer resolver,
                  planos para o futuro.
                </template>
              </b-tooltip>
            </template>
            <b-input
              v-model="$v.businessDescription.$model"
              type="textarea"
              aria-placeholder="Digite a descrição do negócio"
              placeholder="Digite a descrição do negócio"
              @input="onCompanyInput"
            />
            <p v-if="$v.businessDescription.$error" class="help is-danger">
              <span v-if="!$v.businessDescription.required"
                >Preencha este campo.</span
              >
              <span v-else-if="!$v.businessDescription.maxLength"
                >Máximo 200 caracteres.</span
              >
            </p>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column is-4">
          <p><span class="has-text-weight-bold">*</span> Campos obrigatórios</p>
        </div>
        <div class="column is-4 is-offset-4 is-flex is-justify-content-end">
          <b-button
            type="is-link"
            :disabled="disabledSubmit"
            :loading="loadingButton"
            @click="onSubmit"
          >
            Salvar
          </b-button>
        </div>
      </div>
    </section>
  </form>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators';
import { CnpjInput } from '@/components/CnpjInput';
import { IndustrySelect } from '@/components/IndustrySelect';

import { customerStorage } from '@/store';

import { companyService } from '@/services/company';

export default {
  name: 'CompanyForm',
  props: {
    value: {
      type: Object,
    },
  },
  components: {
    CnpjInput,
    IndustrySelect,
  },
  data() {
    return {
      businessDescription: this.value?.businessDescription,
      cnpj: this.value?.cnpj,
      financialYearEndDate: '31/12',
      fullName: this.value?.fullName,
      hasCnpj: this.value?.hasCnpj || true,
      id: this.value?.id,
      industry: this.value?.industry,
      loadingButton: false,
      shareCapital: this.value?.shareCapital,
      website: this.value?.website,
    };
  },
  validations() {
    const shareCapital = this.hasCnpj ? { required } : {};

    return {
      businessDescription: {
        required,
        maxLength: maxLength(200),
      },
      fullName: {
        required,
      },
      industry: {
        required,
      },
      shareCapital,
    };
  },
  beforeMount() {
    this.initializeCompany();
  },
  computed: {
    disabledSubmit() {
      return (
        this.$v.$invalid || (this.hasCnpj && (!this.cnpj || !this.shareCapital))
      );
    },
  },
  methods: {
    getCompany() {
      return {
        businessDescription: this.businessDescription,
        cnpj: this.cnpj,
        financialYearEndDate: this.financialYearEndDate,
        hasCnpj: this.hasCnpj,
        id: this.id,
        industry: this.industry,
        fullName: this.fullName,
        shareCapital: this.shareCapital,
        website: this.website,
      };
    },
    initializeCompany() {
      const customer = customerStorage.getCustomer();

      if (customer) {
        this.businessDescription = customer?.businessDescription;
        this.cnpj = customer?.document;
        this.financialYearEndDate = '31/12';
        this.fullName = customer?.name;
        (this.hasCnpj = !!customer?.document), (this.id = customer?.id);
        this.industry = customer?.industryType?.name;
        this.shareCapital = customer?.shareCapital;
        this.website = customer?.websiteUrl;

        if (this.isCompanyReady()) {
          this.$emit('is-company-finished', true);
          this.emitCompanyReady();
        }
      }
    },
    onCompanyInput() {
      this.$emit('input', this.getCompany());
      this.emitCompanyReady();
    },
    emitCompanyReady() {
      const isCompanyReady = this.isCompanyReady();
      this.$emit('is-company-ready', isCompanyReady);
    },
    isCompanyReady() {
      const { businessDescription, cnpj, fullName, hasCnpj, industry } =
        this.getCompany();
      const cnpjValid = (hasCnpj && cnpj) || !hasCnpj;
      return !!(cnpjValid && businessDescription && fullName && industry);
    },
    async onSubmit() {
      this.loadingButton = true;
      const response = await companyService.saveCompany({
        company: this.getCompany(),
      });

      if (response) {
        this.$emit('is-company-finished', response);
        this.$buefy.toast.open({
          message: 'Dados salvos com sucesso! :)',
          type: 'is-success',
        });
      } else {
        this.$buefy.toast.open({
          message: 'Houve um problema na conexão. Tente novamente! :)',
          type: 'is-danger',
        });
      }

      this.loadingButton = false;
    },
  },
};
</script>
