import { requestService } from '@/services/request';

import { customerStorage } from '@/store';

const PATH = '/api/client';

function getCustomerInfo() {
  const customer = customerStorage.getCustomer();

  return {
    businessDescription: customer?.businessDescription,
    document: customer?.document,
    id: customer?.id,
    industryType: customer?.industryType,
    name: customer?.name,
    shareCapital: customer?.shareCapital,
    shareholders: customer?.shareholders,
    websiteUrl: customer?.websiteUrl,
  };
}

function getAddressRequestBody({ address }) {
  return {
    company: {
      ...getCustomerInfo(),
      location: {
        complement: address?.complement,
        neighborhood: address?.neighborhood,
        residencialNumber: address?.residencialNumber,
        street: address?.street,
        zipcode: address?.zipCode,
      },
      registerStatus: 'RECEBIDO_ENDERECO',
    },
  };
}

export function saveAddress({ address }) {
  const addressBody = getAddressRequestBody({ address });

  // console.log(JSON.stringify(addressBody));

  return requestService
    .post(PATH, addressBody)
    .then((response) => {
      if (response.status === 201) {
        const customer = response?.data?.company;
        customerStorage.setCustomer(customer);
        return true;
      }
    })
    .catch(() => false);
}
