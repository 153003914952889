<template>
  <form>
    <section>
      <div class="columns">
        <div class="column is-half">
          <b-field label="Rua*" :type="{ 'is-danger': $v.street.$error }">
            <b-input
              v-model="$v.street.$model"
              icon="map-marker"
              aria-placeholder="Digite a rua"
              placeholder="Digite a rua"
              @input="onAddressInput"
            />
            <p v-if="$v.street.$error" class="help is-danger">
              Preencha este campo.
            </p>
          </b-field>
        </div>

        <div class="column">
          <b-field
            label="Número*"
            :type="{ 'is-danger': $v.residencialNumber.$error }"
          >
            <b-input
              v-model="$v.residencialNumber.$model"
              icon="numeric"
              aria-placeholder="Digite o número"
              placeholder="Digite o número"
              type="number"
              @input="onAddressInput"
            />
            <p v-if="$v.residencialNumber.$error" class="help is-danger">
              Preencha este campo.
            </p>
          </b-field>
        </div>

        <div class="column">
          <CepInput v-model="zipCode" required @input="onAddressInput" />
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-field
            label="Bairro*"
            :type="{ 'is-danger': $v.neighborhood.$error }"
          >
            <b-input
              v-model="$v.neighborhood.$model"
              icon="home-group"
              aria-placeholder="Digite o bairro"
              placeholder="Digite o bairro"
              @input="onAddressInput"
            />
            <p v-if="$v.neighborhood.$error" class="help is-danger">
              Preencha este campo.
            </p>
          </b-field>
        </div>

        <div class="column">
          <b-field label="Complemento">
            <b-input
              v-model="complement"
              icon="domain-plus"
              aria-placeholder="Digite o complemento"
              placeholder="Digite o complemento"
              @input="onAddressInput"
            />
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column is-4">
          <p><span class="has-text-weight-bold">*</span> Campos obrigatórios</p>
        </div>
        <div class="column is-4 is-offset-4 is-flex is-justify-content-end">
          <b-button
            type="is-link"
            :disabled="disabledSubmit"
            :loading="loadingButton"
            @click="onSubmit"
          >
            Salvar
          </b-button>
        </div>
      </div>
    </section>
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { CepInput } from '@/components/CepInput';

import { customerStorage } from '@/store';

import { addressService } from '@/services/address';

export default {
  name: 'AddressForm',
  props: {
    value: {
      type: Object,
    },
  },
  components: {
    CepInput,
  },
  data() {
    return {
      complement: this.value?.complement,
      loadingButton: false,
      neighborhood: this.value?.neighborhood,
      residencialNumber: this.value?.residencialNumber,
      street: this.value?.street,
      zipCode: this.value?.zipCode,
    };
  },
  validations() {
    return {
      neighborhood: {
        required,
      },
      residencialNumber: {
        required,
      },
      street: {
        required,
      },
      zipCode: {
        required,
      },
    };
  },
  computed: {
    disabledSubmit() {
      return this.$v.$invalid || !this.zipCode;
    },
  },
  beforeMount() {
    this.initializeAddress();
  },
  methods: {
    initializeAddress() {
      const customer = customerStorage.getCustomer();

      if (customer?.location) {
        this.complement = customer?.location?.complement;
        this.neighborhood = customer?.location?.neighborhood;
        this.residencialNumber = customer?.location?.residencialNumber;
        this.street = customer?.location?.street;
        this.zipCode = customer?.location?.zipcode;

        if (this.isAddressReady()) {
          this.$emit('is-address-finished', true);
          this.emitCompanyReady();
        }
      }
    },
    getAddress() {
      return {
        complement: this.complement,
        neighborhood: this.neighborhood,
        residencialNumber: this.residencialNumber,
        street: this.street,
        zipCode: this.zipCode,
      };
    },
    onAddressInput() {
      this.$emit('input', this.getAddress());
      this.emitCompanyReady();
    },
    emitCompanyReady() {
      const isAddressReady = this.isAddressReady();
      this.$emit('is-address-ready', isAddressReady);
    },
    isAddressReady() {
      const { neighborhood, residencialNumber, street, zipCode } =
        this.getAddress();
      return !!(neighborhood && residencialNumber && street && zipCode);
    },
    async onSubmit() {
      this.loadingButton = true;
      const response = await addressService.saveAddress({
        address: this.getAddress(),
      });

      if (response) {
        this.$emit('is-address-finished', response);
        this.$buefy.toast.open({
          message: 'Dados salvos com sucesso! :)',
          type: 'is-success',
        });
      } else {
        this.$buefy.toast.open({
          message: 'Houve um problema na conexão. Tente novamente! :)',
          type: 'is-danger',
        });
      }

      this.loadingButton = false;
    },
  },
};
</script>
