import { GENDER } from './genderConstants';

export function getGenders() {
  return GENDER;
}

export function getGenderLabelByKey(key) {
  return getGenderSelectOptions()?.find((gender) => gender.value === key)
    ?.label;
}

export function getGenderSelectOptions() {
  return [
    { value: GENDER.MASCULINO, label: 'Masculino' },
    { value: GENDER.FEMININO, label: 'Feminino' },
    { value: GENDER.INDEFINIDO, label: 'Não informar' },
  ];
}
