<template>
  <Wrapper>
    <Stepper />
  </Wrapper>
</template>

<script>
import { Stepper } from '@/components/Stepper';
import { Wrapper } from '@/components/Wrapper';

export default {
  name: 'FormView',
  components: {
    Stepper,
    Wrapper,
  },
};
</script>
