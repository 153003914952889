import { INDUSTRIES } from './industryConstants';

export function getIndustries() {
  return INDUSTRIES;
}

export function getIndustryLabelByKey(key) {
  return getIndustriesSelectOptions()?.find(
    (industry) => industry.value === key
  )?.label;
}

export function getIndustriesSelectOptions() {
  return [
    { value: INDUSTRIES.B2B_SOFTWARE, label: 'B2B (outro)' },
    { value: INDUSTRIES.B2C_SOFTWARE, label: 'B2C (outro)' },
    { value: INDUSTRIES.CONSULTANT, label: 'Consultoria' },
    { value: INDUSTRIES.ECOMMERCE, label: 'E-commerce' },
    { value: INDUSTRIES.EDUCATION, label: 'Educação' },
    { value: INDUSTRIES.FINANCIAL_SERVICES, label: 'Serviços financeiros' },
    { value: INDUSTRIES.HEALTHCARE, label: 'Saúde' },
    { value: INDUSTRIES.HOSPITALITY, label: 'Hospital' },
    { value: INDUSTRIES.LEGAL_SERVICES, label: 'Serviços jurídicos' },
    { value: INDUSTRIES.MARKETING_SERVICES, label: 'Serviços de marketing' },
    { value: INDUSTRIES.MARKETPLACE, label: 'Marketplace' },
    { value: INDUSTRIES.OTHER, label: 'Outros' },
    { value: INDUSTRIES.TRAVEL, label: 'Viagens' },
  ];
}
