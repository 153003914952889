<template>
  <section>
    <div class="columns">
      <div class="column">
        <b-field>
          <template #label>
            Escolha uma opção*
            <b-tooltip
              type="is-dark"
              label="Selecione se este(a) sócio(a) é uma pessoa física ou uma pessoa jurídica."
              multilined
            >
              <b-icon size="is-small" icon="help-circle-outline"></b-icon>
            </b-tooltip>
          </template>
          <b-dropdown
            v-model="profileTypeModel"
            aria-role="list"
            @input="
              (value) => {
                onProfileTypeInput(value);
                onCompanyOwnershipInput();
              }
            "
          >
            <template #trigger="{ active }">
              <b-button
                :label="profileTypeLabel"
                type="is-primary"
                :icon-right="active ? 'menu-up' : 'menu-down'"
              />
            </template>
            <b-dropdown-item value="PF" aria-role="listitem"
              >Pessoa física</b-dropdown-item
            >
            <b-dropdown-item value="PJ" aria-role="listitem"
              >Pessoa jurídica</b-dropdown-item
            >
          </b-dropdown>
        </b-field>
      </div>

      <div class="column">
        <Transition name="fade" mode="out-in">
          <b-field
            v-if="profileTypeModel"
            key="percentageOwnership"
            :type="{ 'is-danger': $v.percentageOwnership.$error }"
          >
            <template #label>
              Participação societária*
              <b-tooltip
                type="is-dark"
                label="Digite a porcentagem que este(a) sócio(a) possui da empresa."
                multilined
              >
                <b-icon size="is-small" icon="help-circle-outline"></b-icon>
              </b-tooltip>
            </template>
            <b-input
              v-model="$v.percentageOwnership.$model"
              icon="percent"
              type="number"
              step=".01"
              aria-placeholder="Informe o percentual de participação societária"
              placeholder="Informe o percentual de participação societária"
              @input="onCompanyOwnershipInput"
            />
            <p v-if="$v.percentageOwnership.$error" class="help is-danger">
              <span v-if="!$v.percentageOwnership.required">
                Preencha este campo.
              </span>
              <span v-else-if="!$v.percentageOwnership.between">
                Valor deve ser entre 0 e 100.
              </span>
            </p>
          </b-field>
        </Transition>
      </div>

      <div class="column">
        <Transition name="fade" mode="out-in">
          <b-field
            v-if="profileTypeModel === 'PJ'"
            :type="{ 'is-danger': $v.proofOfOwnership.$error }"
            key="file"
          >
            <template #label>
              Comprovante de sociedade*
              <b-tooltip type="is-dark" label="Exemplo: Contrato Social.">
                <b-icon size="is-small" icon="help-circle-outline"></b-icon>
              </b-tooltip>
            </template>
            <b-upload
              v-model="$v.proofOfOwnership.$model"
              class="file file-label is-primary"
              accept=".jpeg,.jpg,.png,.pdf,.doc,.docx,.xls,.xlsx,.tiff,.ppt,.pptx,.bmp,.gif"
              required
              validationMessage="Por favor, selecione um arquivo válido."
              @input="onCompanyOwnershipInput"
            >
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">
                  {{ proofOfOwnership ? '' : 'Selecione um arquivo' }}
                </span>
              </span>
              <span class="file-name" v-if="proofOfOwnership">
                {{ proofOfOwnership.name }}
              </span>
            </b-upload>
            <p v-if="$v.proofOfOwnership.$error" class="help is-danger">
              <span v-if="!$v.proofOfOwnership.between">
                Valor máximo permitido 5mb.
              </span>
            </p>
          </b-field>
        </Transition>
      </div>
    </div>

    <div class="columns" v-if="profileTypeModel === 'PJ'">
      <div class="column">
        <Transition name="fade">
          <b-field
            :type="{ 'is-danger': $v.certificateOfIncorporation.$error }"
            key="file"
          >
            <template #label>
              Certificado de incorporação*
              <b-tooltip
                type="is-dark"
                label="Exemplo: Comprovante de Inscrição e de Situação Cadastral da sua empresa (CNPJ)."
                multilined
              >
                <b-icon size="is-small" icon="help-circle-outline"></b-icon>
              </b-tooltip>
            </template>
            <b-upload
              v-model="$v.certificateOfIncorporation.$model"
              class="file file-label is-primary"
              accept=".jpeg,.jpg,.png,.pdf,.doc,.docx,.xls,.xlsx,.tiff,.ppt,.pptx,.bmp,.gif"
              required
              validationMessage="Por favor, selecione um arquivo válido."
              @input="onCompanyOwnershipInput"
            >
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">
                  {{ certificateOfIncorporation ? '' : 'Selecione um arquivo' }}
                </span>
              </span>
              <span class="file-name" v-if="certificateOfIncorporation">
                {{ certificateOfIncorporation.name }}
              </span>
            </b-upload>
            <p
              v-if="$v.certificateOfIncorporation.$error"
              class="help is-danger"
            >
              <span v-if="!$v.certificateOfIncorporation.between">
                Valor máximo permitido 5mb.
              </span>
            </p>
          </b-field>
        </Transition>
      </div>
    </div>
  </section>
</template>

<script>
import { between, required } from 'vuelidate/lib/validators';

const FILE_LENGTH_MAX = 5000000; // 5mb

export default {
  name: 'CompanyOwnershipForm',
  props: {
    value: {
      type: Object,
    },
    profileType: {
      type: String,
      required: true,
      validator: (value) => ['PF', 'PJ', ''].includes(value),
    },
  },
  data() {
    return {
      certificateOfIncorporation: this.value?.certificateOfIncorporation,
      percentageOwnership: this.value?.percentageOwnership,
      profileTypeModel: this.profileType,
      proofOfOwnership: this.value?.proofOfOwnership,
    };
  },
  validations() {
    return {
      percentageOwnership: {
        required,
        between: between(0, 100),
      },
      proofOfOwnership: {
        between: function betweenIf() {
          return this.proofOfOwnership
            ? this.proofOfOwnership?.size < FILE_LENGTH_MAX
            : true;
        },
      },
      certificateOfIncorporation: {
        between: function betweenIf() {
          return this.certificateOfIncorporation
            ? this.certificateOfIncorporation?.size < FILE_LENGTH_MAX
            : true;
        },
      },
    };
  },
  computed: {
    isProfileTypePF() {
      return this.profileType === 'PF';
    },
    profileTypeLabel() {
      if (this.profileTypeModel) {
        return this.isProfileTypePF ? 'Pessoa física' : 'Pessoa jurídica';
      }
      return 'Selecione';
    },
  },
  methods: {
    getCompanyOwnership() {
      return {
        certificateOfIncorporation: this.certificateOfIncorporation,
        percentageOwnership: this.percentageOwnership,
        profileType: this.profileTypeModel,
        proofOfOwnership: this.proofOfOwnership,
      };
    },
    onCompanyOwnershipInput() {
      this.$emit('input', this.getCompanyOwnership());
      this.emitCompanyOwnershipReady();
    },
    onProfileTypeInput(value) {
      this.profileTypeModel = value;
    },
    emitCompanyOwnershipReady() {
      const isCompanyOwnershipReady = this.isCompanyOwnershipReady();
      this.$emit('is-company-ownership-ready', isCompanyOwnershipReady);
    },
    isCompanyOwnershipReady() {
      const {
        certificateOfIncorporation,
        percentageOwnership,
        profileType,
        proofOfOwnership,
      } = this.getCompanyOwnership();
      const isProfileTypePFReady = !!(
        percentageOwnership &&
        percentageOwnership <= 100 &&
        profileType
      );
      const isProfileTypePJReady = !!(
        certificateOfIncorporation &&
        this.certificateOfIncorporation?.size < FILE_LENGTH_MAX &&
        isProfileTypePFReady &&
        proofOfOwnership &&
        this.proofOfOwnership?.size < FILE_LENGTH_MAX
      );

      return this.isProfileTypePF ? isProfileTypePFReady : isProfileTypePJReady;
    },
  },
};
</script>
