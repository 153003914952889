import { customerStorage } from '@/store';
import { requestService } from '@/services/request';
import { fileService } from '@/services/file';

const PATH = '/api/client';

function getCustomerInfo() {
  const customer = customerStorage.getCustomer();

  return {
    businessDescription: customer?.businessDescription,
    document: customer?.document,
    id: customer?.id,
    industryType: customer?.industryType,
    location: customer?.location,
    name: customer?.name,
    shareCapital: customer?.shareCapital,
    shareholders: customer?.shareholders,
    websiteUrl: customer?.websiteUrl,
  };
}

function isProfileTypePF(profileType) {
  return profileType === 'PF';
}

function createUuidShareholder({
  firstName = '',
  lastName = '',
  percentageOwnership = '',
}) {
  return window.btoa(
    `${String(firstName).toLowerCase()}_${String(
      lastName
    ).toLowerCase()}_${percentageOwnership}`
  );
}

function isSameShareholder(uuid, uuidNext) {
  return !!(uuid === uuidNext);
}

function formatDate(date) {
  return new Date(date?.replace('-', '/'));
}

function mapShareholders(shareholders) {
  return shareholders?.map((shareholder) => {
    if (isProfileTypePF(shareholder.profileType)) {
      return {
        birthDate: formatDate(shareholder.personalData.birthDate),
        documents: [
          {
            key: 'CPF',
            value: shareholder.documents.cpf,
          },
        ],
        firstName: shareholder.personalData.firstName,
        gender: shareholder.personalData.gender,
        lastName: shareholder.personalData.lastName,
        maritalRegimeProperty: shareholder.documents.regimeOfProperty,
        maritalStatus: shareholder.documents.maritalStatus,
        nationality: shareholder.documents.nationality,
        percentageOwnership: shareholder.companyOwnership.percentageOwnership,
        phone: shareholder.personalData.phone,
        placeOfBirth: shareholder.documents.placeOfBirth,
        uuid: createUuidShareholder({
          firstName: shareholder.personalData.firstName,
          lastName: shareholder.personalData.lastName,
          percentageOwnership: shareholder.companyOwnership.percentageOwnership,
        }),
      };
    }

    return {
      percentageOwnership: shareholder.companyOwnership.percentageOwnership,
      uuid: createUuidShareholder({
        percentageOwnership: shareholder.companyOwnership.percentageOwnership,
      }),
    };
  });
}

function getRequestBody({ shareholders }) {
  return {
    company: {
      ...getCustomerInfo(),
      registerStatus: 'RECEBIDO_SOCIO',
      shareholders: mapShareholders(shareholders),
    },
  };
}

function getDocumentsByShareholders({ shareholders, shareholdersDocuments }) {
  return shareholders.flatMap((shareholder) => {
    const shareholderUuid = createUuidShareholder({
      firstName: shareholder?.firstName || '',
      lastName: shareholder?.lastName || '',
      percentageOwnership: shareholder?.percentageOwnership || '',
    });

    const shareholderDocumentsObj = shareholdersDocuments.find(
      (shareholderDoc) => {
        const shareholderDocUuid = createUuidShareholder({
          firstName: shareholderDoc?.personalData?.firstName || '',
          lastName: shareholderDoc?.personalData?.lastName || '',
          percentageOwnership:
            shareholderDoc?.companyOwnership?.percentageOwnership || '',
        });
        return isSameShareholder(shareholderDocUuid, shareholderUuid);
      }
    );

    if (shareholderDocumentsObj) {
      const proofOfID = shareholderDocumentsObj?.documents?.proofOfID;
      const proofOfResidence =
        shareholderDocumentsObj?.documents?.proofOfResidence;
      const certificateOfIncorporation =
        shareholderDocumentsObj?.companyOwnership?.certificateOfIncorporation;
      const proofOfOwnership =
        shareholderDocumentsObj?.companyOwnership?.proofOfOwnership;
      let documents = [];

      if (proofOfID) {
        documents.push({
          key: 'proofOfID',
          shareholderId: shareholder?.id,
          value: proofOfID,
        });
      }

      if (proofOfResidence) {
        documents.push({
          key: 'proofOfResidence',
          shareholderId: shareholder?.id,
          value: proofOfResidence,
        });
      }

      if (certificateOfIncorporation) {
        documents.push({
          key: 'certificateOfIncorporation',
          shareholderId: shareholder?.id,
          value: certificateOfIncorporation,
        });
      }

      if (proofOfOwnership) {
        documents.push({
          key: 'proofOfOwnership',
          shareholderId: shareholder?.id,
          value: proofOfOwnership,
        });
      }

      return documents;
    }
    return null;
  });
}

function sendDocFiles({ companyId, documents }) {
  return documents.forEach((file) => {
    return fileService.sendFile({
      companyId,
      file: file.value,
      key: file.key,
      shareholderId: file?.shareholderId,
    });
  });
}

export function saveShareholders({ shareholders }) {
  const requestBody = getRequestBody({ shareholders });

  // console.log(JSON.stringify(requestBody));

  return requestService.post(PATH, requestBody).then((response) => {
    if (response.status === 201) {
      const company = response?.data?.company;
      customerStorage.setCustomer(company);
      const documents = getDocumentsByShareholders({
        shareholders: company.shareholders,
        shareholdersDocuments: shareholders,
      });
      return sendDocFiles({ documents, companyId: company?.id });
    }
  });
}

export function initializeShareholdersValues(shareholders) {
  return shareholders?.map((shareholder) => {
    const profileType = shareholder?.firstName ? 'PF' : 'PJ';
    const isCompanyOwnershipReady = !!shareholder?.percentageOwnership; // TODO Change to set documents filled

    return {
      id: shareholder.id,
      companyOwnership: {
        // certificateOfIncorporation: shareholder?.
        percentageOwnership: shareholder?.percentageOwnership,
        profileTypeModel: profileType,
        // proofOfOwnership:
      },
      documents: {
        cpf: shareholder?.documents[0]?.value,
        maritalStatus: shareholder?.maritalStatus,
        nationality: shareholder?.nationality,
        placeOfBirth: shareholder?.placeOfBirth,
        // proofOfID: shareholder?.proofOfID,
        // proofOfResidence: shareholder?.proofOfResidence,
        regimeOfProperty: shareholder?.maritalRegimeProperty,
      },
      isCompanyOwnershipReady,
      isDocumentsReady: true,
      isPersonalDataReady: true,
      isShareholderReady: true,
      personalData: {
        birthDate: shareholder?.birthDate,
        firstName: shareholder?.firstName,
        gender: shareholder?.gender,
        lastName: shareholder?.lastName,
        phone: shareholder?.phone,
      },
      profileType,
    };
  });
}
