<template>
  <b-steps class="pt-5" size="is-small" v-model="step">
    <b-step-item step="1" label="Informações básicas">
      <template v-if="step === 0">
        <div class="card">
          <div class="card-content">
            <CompanyForm
              :value="company"
              @input="onCompanyInput"
              @is-company-finished="setIsCompanyFinished"
              @is-company-ready="setIsCompanyReady"
            />
          </div>
        </div>
      </template>
    </b-step-item>

    <b-step-item step="2" label="Endereço">
      <template v-if="step === 1">
        <div class="card">
          <div class="card-content">
            <AddressForm
              :value="address"
              @input="onAddressInput"
              @is-address-finished="setIsAddressFinished"
              @is-address-ready="setIsAddressReady"
            />
          </div>
        </div>
      </template>
    </b-step-item>

    <b-step-item step="3" label="Sócios">
      <template v-if="step === 2">
        <ShareholdersStep
          @is-shareholders-finished="setIsShareholdersFinished"
        />
      </template>
    </b-step-item>

    <b-step-item step="4" label="Confirmar">
      <template v-if="step === 3">
        <PreviewStep
          @edit-company-clicked="() => setStep(0)"
          @edit-address-clicked="() => setStep(1)"
          @edit-shareholders-clicked="() => setStep(2)"
        />
      </template>
    </b-step-item>

    <template #navigation="{ previous, next }">
      <div class="columns">
        <div class="column">
          <div class="is-flex is-justify-content-space-between pl-4 pr-4">
            <div>
              <b-button
                v-if="!previous.disabled"
                icon-left="arrow-left"
                type="is-link"
                :disabled="previous.disabled"
                @click.prevent="previous.action"
              >
                Anterior
              </b-button>
            </div>
            <b-button
              v-if="step !== 3"
              icon-right="arrow-right"
              type="is-link"
              :disabled="next.disabled || shouldDisableNextStepButton"
              @click.prevent="next.action"
            >
              Avançar
            </b-button>
            <b-button
              v-else
              type="is-link"
              :loading="loadingButton"
              @click.prevent="finish"
            >
              Concluir
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </b-steps>
</template>

<script>
import { AddressForm } from '@/components/AddressForm';
import { CompanyForm } from '@/components/CompanyForm';
import { PreviewStep } from '@/components/PreviewStep';
import { ShareholdersStep } from '@/components/ShareholdersStep';

import { customerStorage } from '@/store';

import { customerService } from '@/services/customer';

export default {
  name: 'Stepper',
  components: {
    AddressForm,
    CompanyForm,
    PreviewStep,
    ShareholdersStep,
  },
  data() {
    return {
      address: null,
      company: null,
      companyId: null,
      shareholders: [],
      isAddressFinished: false,
      isAddressReady: false,
      isCompanyFinished: false,
      isCompanyReady: false,
      isShareholdersFinished: false,
      loadingButton: false,
      registerStatus: '',
      step: 0,
    };
  },
  beforeMount() {
    const customer = customerStorage.getCustomer();
    this.companyId = customer?.id;
    this.registerStatus = customer?.registerStatus;

    if (this.registerStatus === 'RECEBIDO_EMPRESA') {
      this.setStep(1);
    } else if (this.registerStatus === 'RECEBIDO_ENDERECO') {
      this.setStep(2);
    } else if (this.registerStatus === 'RECEBIDO_SOCIO') {
      this.setStep(3);
    } else {
      this.setStep(0);
    }
  },
  computed: {
    shouldDisableNextStepButton() {
      if (this.step === 0) {
        return !(
          (this.isCompanyReady && this.isCompanyFinished) ||
          [
            'RECEBIDO_EMPRESA',
            'RECEBIDO_ENDERECO',
            'RECEBIDO_SOCIO',
            'RECEBIDO',
          ].includes(this.registerStatus)
        );
      }

      if (this.step === 1) {
        return !(
          (this.isAddressReady && this.isAddressFinished) ||
          ['RECEBIDO_ENDERECO', 'RECEBIDO_SOCIO', 'RECEBIDO'].includes(
            this.registerStatus
          )
        );
      }

      if (this.step === 2) {
        return !(
          this.isShareholdersFinished ||
          ['RECEBIDO_SOCIO', 'RECEBIDO'].includes(this.registerStatus)
        );
      }

      return false;
    },
  },
  methods: {
    setIsAddressReady(isAddressReady) {
      this.isAddressReady = isAddressReady;
    },
    setIsAddressFinished(isAddressFinished) {
      this.isAddressFinished = isAddressFinished;
    },
    setIsCompanyReady(isCompanyReady) {
      this.isCompanyReady = isCompanyReady;
    },
    setIsCompanyFinished(isCompanyFinished) {
      this.isCompanyFinished = isCompanyFinished;
    },
    setIsShareholdersFinished(isShareholdersFinished) {
      this.isShareholdersFinished = isShareholdersFinished;
    },
    onAddressInput(address) {
      this.address = address;
    },
    onCompanyInput(company) {
      this.company = company;
    },
    setStep(step) {
      this.step = step;
    },
    async finish() {
      this.loadingButton = true;
      const response = await customerService.updateStatus({
        companyId: this.companyId,
        registerStatus: 'RECEBIDO',
      });

      if (response) {
        this.$router.push('/home');
        this.$buefy.toast.open({
          message: 'Dados salvos com sucesso! :)',
          type: 'is-success',
        });
      } else {
        this.$buefy.toast.open({
          message: 'Houve um problema na conexão. Tente novamente! :)',
          type: 'is-danger',
        });
      }

      this.loadingButton = false;
    },
  },
};
</script>
