<template>
  <div>
    <div class="card mb-5">
      <div class="card-content">
        <form>
          <section>
            <div class="columns">
              <div class="column">
                <b-field>
                  <template #label>
                    Quantidade de sócios*
                    <b-tooltip
                      type="is-dark"
                      label="Digite o número de sócios da sua empresa"
                    >
                      <b-icon
                        size="is-small"
                        icon="help-circle-outline"
                      ></b-icon>
                    </b-tooltip>
                  </template>
                  <b-numberinput
                    v-model="shareholdersQuantity"
                    min="1"
                    max="10"
                  />
                </b-field>
              </div>
              <div class="column is-3 is-flex is-justify-content-end">
                <b-field :class="shareholdersFieldClasses">
                  <b-button
                    :disabled="
                      shareholdersQuantity <= 0 ||
                      shareholdersQuantity < shareholders.length
                    "
                    type="is-link"
                    @click="changeShareholdersStructure"
                  >
                    Confirmar
                  </b-button>
                </b-field>
              </div>
            </div>
          </section>
        </form>
      </div>
    </div>

    <template v-if="shareholders">
      <b-collapse
        class="card mb-3"
        animation="slide"
        v-for="(shareholder, shareholderKey) in shareholders"
        :key="shareholderKey"
        :open="isOpen == shareholderKey"
        @open="isOpen = shareholderKey"
      >
        <template #trigger="props">
          <div class="card-header" role="button">
            <p class="card-header-title">
              <StatusTag :is-success="shareholder.isShareholderReady" />
              <span>{{ shareholderKey + 1 }}° sócio</span><br />
            </p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
          </div>
        </template>
        <div class="card-content">
          <div>
            <p class="subtitle mt-0">Participação na empresa</p>
            <div>
              <hr />
              <section>
                <CompanyOwnershipForm
                  :value="shareholder.companyOwnership"
                  :profile-type="shareholder.profileType"
                  @input="
                    (value) =>
                      onCompanyOwnershipInput({ shareholderKey, value })
                  "
                  @is-company-ownership-ready="
                    (value) =>
                      setIsCompanyOwnershipReady({ shareholderKey, value })
                  "
                />
              </section>
            </div>
          </div>

          <Transition name="fade" mode="out-in">
            <div v-if="isProfileTypePF({ shareholderKey })" key="PF">
              <p class="subtitle mt-5">Informações pessoais</p>
              <div>
                <hr />
                <PersonalDataForm
                  :value="shareholder.personalData"
                  @input="
                    (value) => onPersonalDataInput({ shareholderKey, value })
                  "
                  @is-personal-data-ready="
                    (value) => setIsPersonalDataReady({ shareholderKey, value })
                  "
                />
              </div>

              <p class="subtitle mt-5">Documentos</p>
              <div>
                <hr />
                <DocumentsForm
                  :value="shareholder.documents"
                  @input="
                    (value) => onDocumentsInput({ shareholderKey, value })
                  "
                  @is-documents-ready="
                    (value) => setIsDocumentsReady({ shareholderKey, value })
                  "
                />
              </div>
            </div>
          </Transition>

          <div class="columns">
            <div class="column is-4">
              <p>
                <span class="has-text-weight-bold">*</span>
                Campos obrigatórios
              </p>
            </div>
            <div class="column is-4 is-offset-4 is-flex is-justify-content-end">
              <b-button
                type="is-link"
                :disabled.sync="!shareholder.isShareholderReady"
                :loading="loadingButton"
                @click="onSubmit"
              >
                Salvar
              </b-button>
            </div>
          </div>
        </div>
      </b-collapse>
    </template>
  </div>
</template>

<script>
import { CompanyOwnershipForm } from '@/components/CompanyOwnershipForm';
import { DocumentsForm } from '@/components/DocumentsForm';
import { PersonalDataForm } from '@/components/PersonalDataForm';
import { StatusTag } from '@/components/StatusTag';

import { customerStorage } from '@/store';

import { shareholdersService } from '@/services/shareholders';

export default {
  name: 'ShareholdersStep',
  components: {
    CompanyOwnershipForm,
    DocumentsForm,
    PersonalDataForm,
    StatusTag,
  },
  data() {
    return {
      shareholders: [],
      shareholdersQuantity: 0,
      isOpen: false,
      loadingButton: false,
    };
  },
  beforeMount() {
    this.initializeShareholders();
  },
  computed: {
    shareholdersFieldClasses() {
      return 'is-flex is-flex-direction-column is-justify-content-end';
    },
  },
  methods: {
    initializeShareholders() {
      const customer = customerStorage.getCustomer();

      if (customer) {
        this.shareholdersQuantity = customer?.shareholders?.length;
        this.shareholders = shareholdersService.initializeShareholdersValues(
          customer?.shareholders
        );

        if (this.isShareholdersReady()) {
          this.$emit('is-shareholders-finished', true);
        }
      }
    },
    updateShareholderStructure() {
      const quantityToAdd =
        this.shareholdersQuantity - this.shareholders.length;

      if (quantityToAdd > 0) {
        for (let index = 0; index < quantityToAdd; index++) {
          this.shareholders.push({
            companyOwnership: null,
            documents: null,
            isCompanyOwnershipReady: false,
            isDocumentsReady: false,
            isPersonalDataReady: false,
            isShareholderReady: false,
            personalData: null,
            profileType: '',
          });
        }
      }
    },
    createShareholdersStructure() {
      const shareholders = [...Array(Number(this.shareholdersQuantity)).keys()];

      this.shareholders = shareholders.map(() => ({
        companyOwnership: null,
        documents: null,
        isCompanyOwnershipReady: false,
        isDocumentsReady: false,
        isPersonalDataReady: false,
        isShareholderReady: false,
        personalData: null,
        profileType: '',
      }));
    },
    changeShareholdersStructure() {
      if (this.shareholdersQuantity) {
        if (this.shareholders.length) {
          this.updateShareholderStructure();
          return;
        }

        this.createShareholdersStructure();
      }
    },
    isShareholdersReady() {
      return (
        this.shareholders?.length &&
        this.shareholders?.every(
          (shareholder) => shareholder.isShareholderReady
        )
      );
    },
    isProfileTypePF({ shareholderKey }) {
      return this.shareholders[shareholderKey].profileType === 'PF';
    },
    onCompanyOwnershipInput({ shareholderKey, value }) {
      this.shareholders[shareholderKey].companyOwnership = value;
      this.setProfileTypeInput({ shareholderKey, value: value?.profileType });
    },
    onDocumentsInput({ shareholderKey, value }) {
      this.shareholders[shareholderKey].documents = value;
    },
    onPersonalDataInput({ shareholderKey, value }) {
      this.shareholders[shareholderKey].personalData = value;
    },
    setProfileTypeInput({ shareholderKey, value }) {
      this.shareholders[shareholderKey].profileType = value;
    },
    setIsShareholderReady({ shareholderKey }) {
      const isProfileTypePF = this.isProfileTypePF({ shareholderKey });
      const { isCompanyOwnershipReady, isDocumentsReady, isPersonalDataReady } =
        this.shareholders[shareholderKey];
      const isShareholderReady = isProfileTypePF
        ? isCompanyOwnershipReady && isDocumentsReady && isPersonalDataReady
        : isCompanyOwnershipReady;

      this.$set(this.shareholders, shareholderKey, {
        ...this.shareholders[shareholderKey],
        isShareholderReady,
      });
    },
    setIsDocumentsReady({ shareholderKey, value }) {
      this.shareholders[shareholderKey].isDocumentsReady = value;
      this.setIsShareholderReady({ shareholderKey });
    },
    setIsPersonalDataReady({ shareholderKey, value }) {
      this.shareholders[shareholderKey].isPersonalDataReady = value;
      this.setIsShareholderReady({ shareholderKey });
    },
    setIsCompanyOwnershipReady({ shareholderKey, value }) {
      this.shareholders[shareholderKey].isCompanyOwnershipReady = value;
      this.setIsShareholderReady({ shareholderKey });
    },
    async onSubmit() {
      this.loadingButton = true;
      const shareholdersReadyToSend = this.shareholders?.filter(
        (shareholder) => shareholder.isShareholderReady
      );

      shareholdersService
        .saveShareholders({
          shareholders: shareholdersReadyToSend,
        })
        .then(() => {
          if (this.isShareholdersReady()) {
            this.$emit('is-shareholders-finished', true);
          }

          this.$buefy.toast.open({
            message: 'Dados salvos com sucesso! :)',
            type: 'is-success',
          });
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: 'Houve um problema na conexão. Tente novamente! :)',
            type: 'is-danger',
          });
        })
        .finally(() => (this.loadingButton = false));
    },
  },
};
</script>
