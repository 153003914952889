<template>
  <section class="mb-3">
    <div class="card">
      <div class="card-content">
        <div class="is-flex is-justify-content-space-between">
          <h2 class="subtitle m-0">Sobre a empresa</h2>
          <a
            @click="edit"
            @mouseenter="() => setIconType('is-info')"
            @mouseleave="() => setIconType('is-default')"
          >
            <b-icon icon="pencil" :type="iconType" />
          </a>
        </div>
        <hr />
        <div class="content">
          <div class="columns">
            <div class="column">
              <b-field label="Nome">
                <span>{{ company.fullName }}</span>
              </b-field>
            </div>
            <div class="column">
              <b-field label="CNPJ">
                <span>
                  {{ company.hasCnpj ? getCnpj(company.cnpj) : 'N/A' }}
                </span>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Quantidade de sócios">
                <span>{{ company.shareholders.length }}</span>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column" v-if="company.shareCapital">
              <b-field label="Capital social">
                <span>{{ getShareCapital(company.shareCapital) }}</span>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Tipo de industria">
                <span>{{ getIndustry(company.industry) }}</span>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Descrição do negócio">
                <span>{{ company.businessDescription }}</span>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { industryService } from '@/components/IndustrySelect';

export default {
  name: 'CompanyPreview',
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      iconType: 'is-default',
    };
  },
  methods: {
    edit() {
      this.$emit('edit-company-clicked');
    },
    getCnpj(cnpj) {
      const pattern = '##.###.###/####-##';
      let i = 0;
      return pattern.replace(/#/g, () => cnpj[i++] || '');
    },
    getIndustry(key) {
      return industryService.getIndustryLabelByKey(key);
    },
    getShareCapital(shareCapital) {
      return Number(shareCapital).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    },
    setIconType(iconType) {
      this.iconType = iconType;
    },
  },
};
</script>
