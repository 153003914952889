<template>
  <b-field
    label="CEP*"
    :type="{ 'is-danger': $v.cep.$error && !!validationMessage }"
  >
    <b-input
      v-model="$v.cep.$model"
      icon="numeric"
      v-cleave="getCepMask"
      aria-placeholder="Digite o CEP"
      placeholder="Digite o CEP"
      @input="onCepInput"
      @blur="validateCep"
    />
    <p v-if="$v.cep.$error && !!validationMessage" class="help is-danger">
      {{ validationMessage }}
    </p>
  </b-field>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators';

export default {
  name: 'CepInput',
  props: {
    value: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cep: this.value,
      validationMessage: '',
    };
  },
  validations() {
    if (this.required) {
      return {
        cep: {
          required,
          minLength: minLength(9),
        },
      };
    } else {
      return {
        cep: {
          minLength: minLength(9),
        },
      };
    }
  },
  computed: {
    getCepMask() {
      return {
        blocks: [5, 3],
        delimiterLazyShow: true,
        delimiters: ['-'],
        numericOnly: true,
      };
    },
  },
  methods: {
    onCepInput(cep) {
      if (!this.$v.$invalid) {
        this.$emit('input', this.trimCepMask(cep));
      } else {
        this.$emit('input', null);
      }
    },
    trimCepMask(cep) {
      return cep?.replace(/[^0-9]/g, '');
    },
    validateCep() {
      if (!this.$v.cep.required) {
        this.validationMessage = 'Preencha o campo CEP.';
        return;
      }

      if (!this.$v.cep.minLength) {
        this.validationMessage = 'Preencha 8 caracteres.';
        return;
      }

      this.validationMessage = '';
    },
  },
};
</script>

<style></style>
