<template>
  <b-field
    label="CPF*"
    :type="{ 'is-danger': $v.cpf.$error && !!validationMessage }"
  >
    <b-input
      v-model="$v.cpf.$model"
      aria-placeholder="Informe seu CPF"
      icon="card-account-details"
      placeholder="Informe seu CPF"
      v-cleave="getCpfMask"
      @input="onCpfInput"
      @blur="validateCpf"
    />
    <p v-if="$v.cpf.$error && !!validationMessage" class="help is-danger">
      {{ validationMessage }}
    </p>
  </b-field>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { cpfService } from '@/services/validations';

export default {
  name: 'CpfInput',
  props: {
    value: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cpf: this.value,
      validationMessage: '',
    };
  },
  validations() {
    return {
      cpf: {
        required,
        validateCpf(cpf) {
          if (cpf) {
            const cpfTrim = this.trimCpfMask(cpf);
            return cpfService.isValid(cpfTrim);
          }
          return false;
        },
      },
    };
  },
  computed: {
    getCpfMask() {
      return {
        blocks: [3, 3, 3, 2],
        delimiterLazyShow: true,
        delimiters: ['.', '.', '-'],
        numericOnly: true,
      };
    },
  },
  methods: {
    onCpfInput(cpf) {
      if (!this.$v.$invalid) {
        this.$emit('input', this.trimCpfMask(cpf));
      } else {
        this.$emit('input', null);
      }
    },
    trimCpfMask(cpf) {
      return cpf?.replace(/[^0-9]/g, '');
    },
    validateCpf() {
      if (!this.$v.cpf.validateCpf) {
        this.validationMessage = 'CPF inválido.';
        return;
      }

      if (!this.$v.cpf.required) {
        this.validationMessage = 'Preencha o campo CPF.';
        return;
      }

      this.validationMessage = '';
    },
  },
};
</script>

<style></style>
