<template>
  <section>
    <div class="columns">
      <div class="column">
        <b-field label="Nome*" :type="{ 'is-danger': $v.firstName.$error }">
          <b-input
            v-model="$v.firstName.$model"
            icon="alphabetical"
            aria-placeholder="Informe seu nome"
            placeholder="Informe seu nome"
            @input="onPersonalDataInput"
          />
          <p v-if="$v.firstName.$error" class="help is-danger">
            Preencha este campo.
          </p>
        </b-field>
      </div>

      <div class="column">
        <b-field label="Sobrenome*" :type="{ 'is-danger': $v.lastName.$error }">
          <b-input
            v-model="$v.lastName.$model"
            icon="alphabetical"
            aria-placeholder="Informe seu sobrenome"
            placeholder="Informe seu sobrenome"
            @input="onPersonalDataInput"
          />
          <p v-if="$v.lastName.$error" class="help is-danger">
            Preencha este campo.
          </p>
        </b-field>
      </div>

      <div class="column">
        <b-field label="Gênero*">
          <GenderSelect
            v-model="gender"
            required
            @input="onPersonalDataInput"
          />
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          label="Data de nascimento*"
          :type="{ 'is-danger': $v.birthDate.$error }"
        >
          <b-input
            v-model="$v.birthDate.$model"
            icon="calendar-today"
            type="date"
            expanded
            @input="onPersonalDataInput"
          />
          <p v-if="$v.birthDate.$error" class="help is-danger">
            Preencha este campo.
          </p>
        </b-field>
      </div>

      <div class="column">
        <PhoneInput v-model="phone" required @input="onPersonalDataInput" />
      </div>
    </div>

    <div class="columns">
      <div class="column is-4">
        <p><span class="has-text-weight-bold">*</span> Campos obrigatórios</p>
      </div>
    </div>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { GenderSelect } from '@/components/GenderSelect';
import { PhoneInput } from '@/components/PhoneInput';

export default {
  name: 'PersonalDataForm',
  components: {
    GenderSelect,
    PhoneInput,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    const birthDate = this.value?.birthDate
      ? String(this.value?.birthDate).substring(0, 10)
      : null;

    return {
      birthDate,
      firstName: this.value?.firstName,
      gender: this.value?.gender,
      lastName: this.value?.lastName,
      phone: this.value?.phone,
    };
  },
  validations() {
    return {
      birthDate: {
        required,
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
    };
  },
  methods: {
    getPersonalData() {
      return {
        birthDate: this.birthDate,
        firstName: this.firstName,
        gender: this.gender,
        lastName: this.lastName,
        phone: this.phone,
      };
    },
    onPersonalDataInput() {
      this.$emit('input', this.getPersonalData());
      this.emitPersonalDataReady();
    },
    emitPersonalDataReady() {
      const isPersonalDataReady = this.isPersonalDataReady();
      this.$emit('is-personal-data-ready', isPersonalDataReady);
    },
    isPersonalDataReady() {
      const { birthDate, firstName, gender, lastName, phone } =
        this.getPersonalData();
      return !!(birthDate && firstName && gender && lastName && phone);
    },
  },
};
</script>
