import { MARITAL_STATUS } from './maritalStatusConstants';

export function getMaritalStatus() {
  return MARITAL_STATUS;
}

export function getMaritalStatusLabelByKey(key) {
  return getMaritalStatusSelectOptions()?.find(
    (maritalStatus) => maritalStatus.value === key
  )?.label;
}

export function getMaritalStatusSelectOptions() {
  return [
    { value: MARITAL_STATUS.CASADO, label: 'Casado' },
    { value: MARITAL_STATUS.DIVORCIADO, label: 'Divorciado' },
    { value: MARITAL_STATUS.SEPARADO, label: 'Separado' },
    { value: MARITAL_STATUS.SOLTEIRO, label: 'Solteiro' },
    { value: MARITAL_STATUS.VIUVO, label: 'Viúvo' },
  ];
}

export function isMaritalStatusMarried(maritalStatus) {
  return maritalStatus === MARITAL_STATUS.CASADO;
}
