<template>
  <b-select
    :value="maritalStatus"
    :required="required"
    icon="ring"
    expanded
    placeholder="Selecione o estado civil"
    aria-placeholder="Selecione o estado civil"
    @input="onMaritalStatusInput"
  >
    <option hidden value="">Selecione o estado civil</option>
    <option
      v-for="maritalStatus in getMaritalStatus"
      :value="maritalStatus.value"
      :key="maritalStatus.value"
    >
      {{ maritalStatus.label }}
    </option>
  </b-select>
</template>

<script>
import { getMaritalStatusSelectOptions } from './maritalStatusService';

export default {
  name: 'MaritalStatusSelect',
  props: {
    value: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maritalStatus: this.value,
    };
  },
  computed: {
    getMaritalStatus() {
      return getMaritalStatusSelectOptions();
    },
  },
  methods: {
    onMaritalStatusInput(maritalStatus) {
      this.$emit('input', maritalStatus);
    },
  },
};
</script>
