import { requestService } from '@/services/request';

export function sendFile({ companyId, file, key, shareholderId }) {
  const formData = new FormData();
  formData.append('file', file);

  return requestService.post(
    `/api/client/${companyId}/document/${shareholderId}/${key}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
}
