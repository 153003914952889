<template>
  <section class="mb-3">
    <div class="card">
      <div class="card-content">
        <div class="is-flex is-justify-content-space-between">
          <h2 class="subtitle m-0">Sócios</h2>
          <a
            @click="edit"
            @mouseenter="() => setIconType('is-info')"
            @mouseleave="() => setIconType('is-default')"
          >
            <b-icon icon="pencil" :type="iconType" />
          </a>
        </div>
        <div class="content">
          <div v-for="(shareholders, index) in getShareholders" :key="index">
            <hr />
            <div v-if="shareholders.profileType === 'PF'">
              <div class="columns" v-if="shareholders.personalData">
                <div class="column">
                  <b-field label="Nome">
                    <span>{{ shareholders.personalData.firstName }}</span>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Sobrenome">
                    <span>{{ shareholders.personalData.lastName }}</span>
                  </b-field>
                </div>
              </div>
              <div class="columns" v-if="shareholders.documents">
                <div class="column">
                  <b-field label="CPF">
                    <span>{{ getCpf(shareholders.documents.cpf) }}</span>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Nacionalidade">
                    <span>{{ shareholders.documents.nationality }}</span>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Cidade de nascimento">
                    <span>{{ shareholders.documents.placeOfBirth }}</span>
                  </b-field>
                </div>
              </div>
              <div class="columns" v-if="shareholders.personalData">
                <div class="column">
                  <b-field label="Data de nascimento">
                    <span>{{
                      getBirthDate(shareholders.personalData.birthDate)
                    }}</span>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Telefone">
                    <span>{{ getPhone(shareholders.personalData.phone) }}</span>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Gênero">
                    <span>{{
                      getGender(shareholders.personalData.gender)
                    }}</span>
                  </b-field>
                </div>
              </div>
              <div
                class="columns"
                v-if="shareholders.documents && shareholders.companyOwnership"
              >
                <div class="column">
                  <b-field label="Estado civil">
                    <span>{{
                      getMaritalStatus(shareholders.documents.maritalStatus)
                    }}</span>
                  </b-field>
                </div>
                <div
                  v-if="
                    isMaritalStatusMarried(shareholders.documents.maritalStatus)
                  "
                  class="column"
                >
                  <b-field label="Regime matrimonial">
                    <span>{{
                      getRegimeOfProperty(
                        shareholders.documents.regimeOfProperty
                      )
                    }}</span>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Participação societária">
                    <span>
                      {{ shareholders.companyOwnership.percentageOwnership }}%
                    </span>
                  </b-field>
                </div>
              </div>
            </div>
            <div v-else>
              <div v-if="shareholders.companyOwnership" class="columns">
                <div class="column">
                  <b-field label="Participação societária">
                    <span>
                      {{ shareholders.companyOwnership.percentageOwnership }}%
                    </span>
                  </b-field>
                </div>
                <div
                  class="column"
                  v-if="shareholders.companyOwnership.proofOfOwnership"
                >
                  <b-field label="Comprovante de sociedade">
                    <span>
                      {{ getProofOfOwnershipName(shareholders) }}
                    </span>
                  </b-field>
                </div>
                <div
                  class="column"
                  v-if="
                    shareholders.companyOwnership.certificateOfIncorporation
                  "
                >
                  <b-field label="Certificado de incorporação">
                    <span>
                      {{ getCertificateOfIncorporationName(shareholders) }}
                    </span>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { genderService } from '@/components/GenderSelect';
import { maritalStatusService } from '@/components/MaritalStatusSelect';
import { regimeOfPropertyService } from '@/components/RegimeOfPropertySelect';

export default {
  name: 'ShareholdersPreview',
  props: {
    shareholders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      iconType: 'is-default',
    };
  },
  computed: {
    getShareholders() {
      return this.shareholders?.filter((shareholders) => {
        return shareholders.profileType === 'PJ'
          ? shareholders.companyOwnership
          : shareholders.personalData &&
              shareholders.documents &&
              shareholders.companyOwnership;
      });
    },
  },
  methods: {
    edit() {
      this.$emit('edit-shareholders-clicked');
    },
    getBirthDate(birthDate) {
      if (String(birthDate).includes('T')) {
        return new Date(birthDate).toLocaleDateString();
      }

      return new Date(birthDate.replace('-', '/')).toLocaleDateString();
    },
    getCpf(cpf) {
      const pattern = '###.###.###-##';
      let i = 0;
      return pattern.replace(/#/g, () => cpf[i++] || '');
    },
    getGender(gender) {
      return genderService.getGenderLabelByKey(gender);
    },
    getMaritalStatus(maritalStatus) {
      return maritalStatusService.getMaritalStatusLabelByKey(maritalStatus);
    },
    getPhone(phone) {
      const pattern =
        phone.length === 13 ? '### (##) ####-####' : '### (##) #####-####';
      let i = 0;
      return pattern.replace(/#/g, () => phone[i++] || '');
    },
    getRegimeOfProperty(regimeOfProperty) {
      return regimeOfPropertyService.getRegimeOfPropertyLabelByKey(
        regimeOfProperty
      );
    },
    getProofOfOwnershipName(shareholders) {
      return shareholders?.companyOwnership?.proofOfOwnership?.name;
    },
    getCertificateOfIncorporationName(shareholders) {
      return shareholders?.companyOwnership?.certificateOfIncorporation?.name;
    },
    isMaritalStatusMarried(maritalStatus) {
      return maritalStatusService.isMaritalStatusMarried(maritalStatus);
    },
    setIconType(iconType) {
      this.iconType = iconType;
    },
  },
};
</script>
