import { REGIME_OF_PROPERTY } from './regimeOfPropertyConstants';

export function getRegimeOfProperties() {
  return REGIME_OF_PROPERTY;
}

export function getRegimeOfPropertyLabelByKey(key) {
  return getRegimeOfPropertySelectOptions()?.find(
    (regimeOfProperty) => regimeOfProperty.value === key
  )?.label;
}

export function getRegimeOfPropertySelectOptions() {
  return [
    {
      value: REGIME_OF_PROPERTY.COMUNHAO_PARCIAL,
      label: 'Comunhão parcial de bens',
    },
    {
      value: REGIME_OF_PROPERTY.COMUNHAO_UNIVERSAL,
      label: 'Comunhão universal de bens',
    },
    {
      value: REGIME_OF_PROPERTY.PARTICIPACAO_FINAL,
      label: 'Participação final nos aquestos',
    },
    {
      value: REGIME_OF_PROPERTY.SEPARACAO_CONVENCIONAL_BENS,
      label: 'Separação de bens',
    },
  ];
}
