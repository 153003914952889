<template>
  <b-select
    v-model="industry"
    expanded
    :required="required"
    icon="factory"
    placeholder="Tipo de indústria"
    @input="onIndustryInput"
  >
    <option
      v-for="industry in getIndustries"
      :value="industry.value"
      :key="industry.value"
    >
      {{ industry.label }}
    </option>
  </b-select>
</template>

<script>
import { getIndustriesSelectOptions } from './industryService';

export default {
  name: 'IndustrySelect',
  props: {
    value: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      industry: this.value,
    };
  },
  computed: {
    getIndustries() {
      return getIndustriesSelectOptions();
    },
  },
  methods: {
    onIndustryInput(industry) {
      this.$emit('input', industry);
    },
  },
};
</script>
