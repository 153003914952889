export const INDUSTRIES = {
  B2B_SOFTWARE: 'B2B_SOFTWARE',
  B2C_SOFTWARE: 'B2C_SOFTWARE',
  CONSULTANT: 'CONSULTANT',
  ECOMMERCE: 'ECOMMERCE',
  EDUCATION: 'EDUCATION',
  FINANCIAL_SERVICES: 'FINANCIAL_SERVICES',
  HEALTHCARE: 'HEALTHCARE',
  HOSPITALITY: 'HOSPITALITY',
  LEGAL_SERVICES: 'LEGAL_SERVICES',
  MARKETING_SERVICES: 'MARKETING_SERVICES',
  MARKETPLACE: 'MARKETPLACE',
  OTHER: 'OTHER',
  TRAVEL: 'TRAVEL',
};
