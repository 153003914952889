import { customerStorage } from '@/store';
import { requestService } from '@/services/request';

const PATH = '/api/client';

function getCompanyRequestBody({ company }) {
  const customer = customerStorage.getCustomer();

  return {
    company: {
      businessDescription: company?.businessDescription,
      document: company?.cnpj,
      financialYearEndDate: company?.financialYearEndDate,
      id: company?.id,
      industryType: {
        name: company?.industry,
      },
      location: customer?.location,
      name: company?.fullName,
      registerStatus: 'RECEBIDO_EMPRESA',
      shareCapital: company?.shareCapital,
      shareholders: customer?.shareholders,
      websiteUrl: company?.website,
    },
  };
}

export function saveCompany({ company }) {
  const companyBody = getCompanyRequestBody({ company });

  // console.log(JSON.stringify(companyBody));

  return requestService
    .post(PATH, companyBody)
    .then((response) => {
      if (response.status === 201) {
        const customer = response?.data?.company;
        customerStorage.setCustomer(customer);
        customerStorage.setCustomerCompanyId(customer?.id);
        return true;
      }
    })
    .catch(() => false);
}
