<template>
  <b-select
    :value="gender"
    :required="required"
    icon="gender-female"
    expanded
    placeholder="Informe seu gênero"
    @input="onInputGender"
  >
    <option
      v-for="gender in getGenders()"
      :value="gender.value"
      :key="gender.value"
    >
      {{ gender.label }}
    </option>
  </b-select>
</template>

<script>
import { getGenderSelectOptions } from './genderService';

export default {
  name: 'GenderSelect',
  props: {
    value: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gender: this.value,
    };
  },
  methods: {
    getGenders() {
      return getGenderSelectOptions();
    },
    onInputGender(gender) {
      this.$emit('input', gender);
    },
  },
};
</script>
